import * as React from "react"
import "../scss/ContactsPage.scss"
import Seo from "../components/seo/seo"

import Header from "../components/header/header"
import Footer from "../components/footer/footer"

const Contacts = props => {
  const staticContents = props.pageContext.staticContent
  const menu = props.pageContext.menu
  const staticHeaderPage = props.pageContext.staticHeaderPage

  return (
    <div>
      <div className="container">
        <Header
          menu={menu}
          siteTitle={staticContents["<title>"]}
          contents={staticHeaderPage}
          displayLogoLinks={false}
        />
        <Seo
          keywordsContent={staticContents["<meta> keywords"]}
          descriptionContent={staticContents["<meta> description"]}
          title={staticContents["<title>"]}
        />
        <div className="contacts row mt-4">
          <div className="row">
            <h1
            className="col-lg-12"
            dangerouslySetInnerHTML={{ __html: staticContents["Address Text"] }}
            />
          </div>
          <div className="row mt-3">
            <h2
              className="col-lg-12"
              dangerouslySetInnerHTML={{ __html: staticContents["Phone HTML"] }}
            />
          </div>
          <div className="row mt-3">
            <h2
              dangerouslySetInnerHTML={{ __html: staticContents["Email HTML"] }}
              className="col-lg-12"
            ></h2>
          </div>
          <div className="map-wrapper col-lg-12 mb-6 mt-4">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1269.9801251458707!2d30.524399332017502!3d50.46046488337628!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4ce46932962cd%3A0xe286359f556d5b1f!2z0LLRg9C70LjRhtGPINCG0LPQvtGA0ZbQstGB0YzQutCwLCAxNNCQLCDQmtC40ZfQsiwgMDQwNzA!5e0!3m2!1suk!2sua!4v1631128920669!5m2!1suk!2sua"
              allowFullScreen=""
              loading="lazy"
            />
          </div>
        </div>
      </div>
      <Footer recaptchaSettings={props.pageContext.recaptchaSettings} staticContactsForm={props.pageContext.staticContactsForm} staticContactsPage={props.pageContext.staticContent} />

    </div>
  )
}

export default Contacts
